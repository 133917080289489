import * as React from 'react';
import { Layout, Wrapper } from '../components';
import Helmet from 'react-helmet';
import config from '../../config/SiteConfig';
import Navigation from '../components/Navigation';

export default class NotFoundPage extends React.Component<any> {
  public render() {
    return (
      <Layout>
        <Helmet title={`404 not found | ${config.siteTitle}`} />
        <Navigation />
        <div style={{ margin: '0 auto' }}>
          <p>Not found.</p>
        </div>
      </Layout>
    );
  }
}
